import React from "react";

const tutorials = [
  {
    title: "Windows Setup for FB Marketplace",
    url: "https://www.youtube.com/embed/Mh9ZpshX-_w",
  },
  {
    title: "Mac OS Setup for FB Marketplace",
    url: "https://www.youtube.com/embed/QxIZGhvkRw4",
  },
  {
    title: "Tutorial: Fast Stone Resizer",
    url: "https://www.youtube.com/embed/7R_lhwDAKSw",
  },
  {
    title: "How to Bulk Post on OfferUp",
    url: "https://www.youtube.com/embed/v7yEX3v_daY",
  },
  {
    title: "How To Bulk Post On Craigslist",
    url: "https://www.youtube.com/embed/ddkezgjbTjw",
  },
];


export default function Tutorials() {
  return (
    <div className="row">
      {
        tutorials.map(({ title, url }) =>
          <div key={url} className="col-4 my-3">
            <div className="card">
              <div className="card-header">{title}</div>
              <div className="card-body d-flex justify-content-center">
                <div className="ratio ratio-16x9">
                  <iframe className="embed-responsive-item" src={url}
                        title={title} frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                        allowFullScreen ></iframe>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}
