import { combineReducers } from "redux";
import utf8 from "utf8";
import base64 from "base-64";
import { deflateSync } from "zlib";


const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_USER":
      if (!action.user.email) {
        localStorage.removeItem("user");
        localStorage.removeItem("listings");
        return {};
      } else if (typeof (Storage) !== undefined) { // eslint-disable-line valid-typeof
        if (action.user.listings && action.user.listings.length) {
          action.user.campaigns = {};
          action.user.groups = {};

          for (const listing of action.user.listings) {
            if (listing.campaign) {
              action.user.campaigns[listing.campaign] = true;
            }

            if (listing.groups && listing.groups.length) {
              for (const group of listing.groups) {
                action.user.groups[group] = true;
              }
            }
          }
          const compressedListingData = deflateSync(JSON.stringify(action.user.listings)).toString("base64");
          localStorage.setItem("listings", compressedListingData);
        }
        localStorage.setItem("user", base64.encode(utf8.encode(JSON.stringify({ ...action.user, listings: [] }))));
        return action.user;
      }
      return action.user;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  test: userReducer,
});

export default rootReducer;
