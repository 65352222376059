import React, { Component } from "react";

export default class TextInput extends Component {
    render = () => {
      const { display = true, name, label, type = "text", placeholder, defaultValue, onChange, required, disabled, min, max, maxLength, pattern, prefix } = this.props;


      return !!display &&
            (<div className="mb-3 has-validation">
              <label htmlFor={name} className="form-label mb-1">{label}</label>
              <div className="input-group">
                {!!prefix && <span className="input-group-text">{prefix}</span>}
                <input
                    type={type}
                    name={name}
                    id={name}
                    aria-describedby={name}
                    placeholder={placeholder}
                    value={defaultValue}
                    className="form-control"
                    onChange={onChange}
                    min={min}
                    max={max}
                    maxLength={maxLength}
                    pattern={pattern}
                    required={required}
                    disabled={disabled}
                    />
                {required && <div className="invalid-feedback">required</div>}
              </div>
            </div>);
    }
}
