import React, { Component } from "react";

export default class Radio extends Component {
    render = () => {
      const { display = true, name, label, options, defaultValue, onChange, labels } = this.props;


      return !!display &&
            (<div className="mb-3">
              <label htmlFor={name} className="form-label mb-1">{label}</label>
              {options.map((option, i) => (
                <div className="form-check" key={option}>
                  <input className="form-check-input" type="radio" name={name} id={option} onChange={onChange} checked={option === defaultValue} />
                  <label className="form-check-label" htmlFor={option}>
                    {labels ? labels[i] : option}
                  </label>
                </div>
              ))}
            </div>);
    }
}
