import React, { Component } from "react";
import { ReactSortable } from "react-sortablejs";

export default class Images extends Component {
    render = () => {
      const { images, imageLimit, onChange, setList, removeImage, imageUploadActive, imagesMissing, disabled } = this.props;


      return (
        <div className="mb-3">
          <label htmlFor="images" className="form-label">Images</label>
          <ReactSortable className="d-flex flex-row flex-wrap" list={images} setList={setList} direction="horizontal" draggable=".flex-column" animation="200" revertOnSpill={true}>
            <>
              {
                images.map(img => (
                  <div key={String(img).substring(0, 100)} className="col-sm-3 d-flex flex-column pe-3 pb-3">
                    <img src={String(img)} alt="" className="img-fluid rounded-3" />
                    <button className="btn btn-link mt-auto" onClick={() => removeImage(img)}><small>remove</small></button>
                  </div>
                ))
              }
              {
                images.length < imageLimit &&
                  <div className="col-sm-3 pe-3 pb-3">
                    <div className="img-picker">
                      <div className={`form-control btn btn-outline-dark btn-block img-upload-btn ${imageUploadActive ? "disabled" : ""} ${imagesMissing ? "is-invalid" : ""}`}>
                        <i className="fas fa-plus"></i>
                        <input type="file" multiple={true} accept="image/jpeg, image/png, image/bmp, image/tiff, image/gif" id="images" onChange={onChange} disabled={disabled} required={!images.length} />
                      </div>
                    </div>
                  </div>
              }
            </>
          </ReactSortable>
        </div>
      );
    }
}
