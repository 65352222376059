import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MDBDataTableV5 } from "mdbreact";
import { updateUser } from "../actions";

const baseUrl = "https://us-central1-lazyposter.cloudfunctions.net";

class ViewListings extends Component {
    componentDidMount = async () => {
      const { user, updateUser } = this.props;

      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");

      const listings = await fetch(`${baseUrl}/getListings?jwt_token=${user.jwt_token}&includeTemplates=true`, {
        method: "GET",
        headers,
      }).then(res => res.json());

      updateUser({ ...user, listings });

      const { email, uid, subscription_active, expire_time, subscriptions, subscription_type, types, scheduling, jwt_token } = await fetch(`${baseUrl}/checkSubscription?jwt_token=${user.jwt_token}`, {
        method: "GET",
        headers,
      }).then(res => res.json());

      updateUser({ ...user, listings, email, uid, subscription_active, expire_time, subscriptions, subscription_type, types, scheduling, jwt_token });
    }

    delete = (id, askConfirmation = true) => {
      const isTemplateView = this.props.match.path === "/templates";
      if (askConfirmation) {
        const confirmation = window.confirm(`Are you sure you want to delete this ${isTemplateView ? "template" : "listing"}?`);
        if (!confirmation) {
          return;
        }
      }

      const { user, updateUser } = this.props;

      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      fetch(`${baseUrl}/deleteListing/${id}?jwt_token=${user.jwt_token}`, {
        method: "GET",
        headers,
      })
        .then(response => {
          switch (response.status) {
            case 400:
              throw new Error("Invalid credentials!");
            case 401:
              throw new Error("Subscription expired! Please renew subscription.");
            case 404:
              throw new Error("Listing not found.");
            case 200:
              return response.json();
            default:
              throw new Error(`error connecting to server: ${response.status} ${response.statusText}`);
          }
        }).then(() => updateUser({ ...user, listings: user.listings.filter(l => l.id !== id) }))
        .catch(alert);
    }

    deleteSelected = async () => {
      const isTemplateView = this.props.match.path === "/templates";
      const toDelete = [];
      const checkboxes = document.querySelectorAll(".checkbox");

      for (const checkbox of checkboxes) {
        if (checkbox.checked && checkbox.id) {
          toDelete.push(checkbox.id);
        }
      }

      console.log(toDelete.length);

      const confirmation = window.confirm(`Are you sure you want to delete these ${toDelete.length} ${isTemplateView ? "templates" : "listings"}?`);
      if (confirmation) {
        for (const id of toDelete) {
          await this.delete(id, false);
        }
      }
    }

    getThumbUrl = url => {
      if (!url) {
        return "./missingimg-stockphoto-612x612.jpg";
      }

      if (!url.includes("/lazyposter-images/")) {
        return url;
      }
      const urlArray = url.split("/");
      urlArray.splice(urlArray.length - 1, 0, "thumbnails");
      return urlArray.join("/");
    }

    selectAll = e => document.querySelectorAll(".checkbox").forEach(c => (c.checked = e.target.checked));


    prepareColumns = (listings, isTemplateView = false) => ({
      columns: [
        { label: <input key={Date()} className="checkbox" type="checkbox" onChange={this.selectAll}/>, field: "checkbox", sort: "disabled" },
        { label: "", field: "image", sort: "disabled" },
        { label: isTemplateView ? "Name" : "Title", field: "title" },
        { label: "Price", field: "price" },
        { label: "Category", field: "category" },
        { label: "Campaign", field: "campaign" },
        { label: "Location", field: "location" },
        { label: "", field: "btn", sort: "disabled" },
      ],
      rows: listings
        .filter(l => !!l.template === !!isTemplateView)
        .map(l => ({ ...l, images: l.images?.length ? l.images : [""] }))
        .map(l => ({
          checkbox: <input key={"cb" + l.id} id={l.id} className="checkbox" type="checkbox" />,
          image: <img src={this.getThumbUrl(l.images[0])} key={"img" + l.id} height="45" alt="" />,
          title: l.templateName || l.title || (l.propertyType ? `${l.propertyType} ${l.rentOrSale}` : `${l.make} ${l.model} ${l.year}`),
          price: l.price,
          category: l.subcategory3 || l.subcategory2 || l.subcategory1 || l.category || l.offerupSubcategory || l.offerupCategory || l.craigslistCategory || l.propertyType || l.vehicleType,
          campaign: l.campaign || "",
          location: l.location,
          description: l.description,
          btn: [
            <Link key={"ebtn" + l.id} to={`edit-listing/${l.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-dark me-3"><i className="fas fa-edit"></i> Edit</Link>,
            <button key={"dbtn" + l.id} className="btn btn-danger" onClick={() => this.delete(l.id)} disabled={!this.props.user.subscription_active}><i className="fas fa-trash-alt"></i> Delete</button>,
          ],
        })),
    })

    render = () => {
      const { user } = this.props;
      const isTemplateView = this.props.match.path === "/templates";


      const listings = user.listings || [];
      const { columns, rows } = this.prepareColumns(listings, isTemplateView);

      return (
        <div className="mt-3">
          <MDBDataTableV5 key={this.props.match.path} hover data={{ columns, rows }} entries={100} displayEntries={false} searchTop searchBottom={false} />
          <div className="col3 ms-4 mb-4">
            <button className="btn btn-danger" onClick={this.deleteSelected} disabled={!user.subscription_active}><i className="fas fa-trash-alt"></i> Delete Selected</button>
          </div>
        </div>
      );
    }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  updateUser: (user) => dispatch(updateUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewListings);
