import React, { Component } from "react";
import { connect } from "react-redux";

class Download extends Component {
    render = () => {
      const { subscription_type, subscriptions, scheduling, replybot } = this.props.user;

      return (
        <div className="ps-3">
          {
            subscription_type === "app_access" && subscriptions.includes("facebook")
              ? <>
                <div className="row">
                  <h4 className="text-dark py-3">FB Marketplace</h4>
                </div>

                <a href="https://storage.googleapis.com/lazyposter-app/windows/lp-win_latest.zip" download className="btn btn-dark btn-lg me-4">
                  <i className="fab fa-windows"></i> Windows
                </a>

                <a href="https://storage.googleapis.com/lazyposter-app/macos/lp-macos-x64_latest.zip" download className="btn btn-dark btn-lg me-4">
                  <i className="fab fa-apple"></i> MacOS (iseries)
                </a>

                <a href="https://storage.googleapis.com/lazyposter-app/macos/lp-macos-arm64_latest.zip" download className="btn btn-dark btn-lg me-4">
                  <i className="fab fa-apple"></i> MacOS (mseries)
                </a>

                {/* <a href="https://storage.googleapis.com/lazyposter-app/linux/lp-linux_latest.zip" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg">
                                <i className="fab fa-ubuntu"></i> Linux
                            </a> */}

                <a href="https://storage.googleapis.com/lazyposter-app/guides/instructions_fb.pdf" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg">
                  <i className="far fa-file-pdf"></i> Guide
                </a>

                <hr className="mt-5" />
              </>
              : null
          }

          {
            subscription_type === "app_access" && subscriptions.includes("craigslist")
              ? <>
                <div className="row">
                  <h4 className="text-dark py-3">Craigslist</h4>
                </div>

                <a href="https://storage.googleapis.com/lazyposter-app/windows/lp-win-cl_latest.zip" download className="btn btn-dark btn-lg me-4">
                  <i className="fab fa-windows"></i> Windows
                </a>

                <a href="https://storage.googleapis.com/lazyposter-app/macos/lp-macos-x64-cl_latest.zip" download className="btn btn-dark btn-lg me-4">
                  <i className="fab fa-apple"></i> MacOS (iseries)
                </a>

                <a href="https://storage.googleapis.com/lazyposter-app/macos/lp-macos-arm64-cl_latest.zip" download className="btn btn-dark btn-lg me-4">
                  <i className="fab fa-apple"></i> MacOS (mseries)
                </a>

                <a href="https://storage.googleapis.com/lazyposter-app/guides/instructions_cl.pdf" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg me-4">
                  <i className="far fa-file-pdf"></i> Guide
                </a>

                <hr className="mt-5" />
              </>
              : null
          }

          {
            subscription_type === "app_access" && subscriptions.includes("offerup")
              ? <>
                <div className="row">
                  <h4 className="text-dark py-3">Offerup</h4>
                </div>

                <a href="https://storage.googleapis.com/lazyposter-app/offerup/offerup.zip" download className="btn btn-dark btn-lg me-4">
                  <i className="fab fa-windows"></i> Windows
                </a>

                <a href="https://storage.googleapis.com/lazyposter-app/guides/instructions_offerup.pdf" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg">
                  <i className="far fa-file-pdf"></i> Guide
                </a>

                <hr className="mt-5" />
              </>
              : null
          }
          {
            replybot
              ? <>
                <div className="row">
                  <h4 className="text-dark py-3">AutoReply</h4>
                </div>


                <a href="https://storage.googleapis.com/lazyposter-app/windows/lp-autoreply-win_latest.zip" download className="btn btn-dark btn-lg me-4">
                  <i className="fab fa-windows"></i> Windows
                </a>

                <a href="https://storage.googleapis.com/lazyposter-app/macos/lp-autoreply-macos-x64_latest.zip" download className="btn btn-dark btn-lg me-4">
                  <i className="fab fa-apple"></i> MacOS (iseries)
                </a>

                <a href="https://storage.googleapis.com/lazyposter-app/macos/lp-autoreply-macos-arm64_latest.zip" download className="btn btn-dark btn-lg me-4">
                  <i className="fab fa-apple"></i> MacOS (mseries)
                </a>

                <hr className="mt-5" />
              </>
              : null
          }
          <>
            <div className="row">
              <h4 className="text-dark py-3">Additional Guides:</h4>
            </div>

            <a href="https://storage.googleapis.com/lazyposter-app/guides/getting_started.pdf" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg me-4 mt-4">
              <i className="far fa-file-pdf"></i> Getting Started
            </a>
            {
              scheduling &&
                <a href="https://storage.googleapis.com/lazyposter-app/guides/guide_schedules.pdf" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg me-4 mt-4">
                  <i className="far fa-file-pdf"></i> Scheduling/Campaign
                </a>
            }

            <a href="https://storage.googleapis.com/lazyposter-app/guides/guide_csv_import.pdf" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg me-4 mt-4">
              <i className="far fa-file-pdf"></i> CSV Import/Export
            </a>

            <a href="https://storage.googleapis.com/lazyposter-app/guides/guide_settings.pdf" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg me-4 mt-4">
              <i className="far fa-file-pdf"></i> Computer Config
            </a>

            <a href="https://storage.googleapis.com/lazyposter-app/guides/error_guide.pdf" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg mt-4">
              <i className="far fa-file-pdf"></i> Error Guide
            </a>

            <hr className="mt-3" />
          </>
        </div>
      );
    }
}

const mapStateToProps = state => ({
  user: state.user,
});


export default connect(
  mapStateToProps,
  null
)(Download);
