import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUser } from "../actions";

const baseUrl = "https://us-central1-lazyposter.cloudfunctions.net";

const days = {
  "**": "24/7",
  "*": "daily",
  "1": "monday",
  "2": "tuesday",
  "3": "wednesday",
  "4": "thursday",
  "5": "friday",
  "6": "saturday",
  "7": "sunday",
};

const hours = {
  "00 0": "00:00",
  "30 0": "00:30",
  "00 1": "01:00",
  "30 1": "01:30",
  "00 2": "02:00",
  "30 2": "02:30",
  "00 3": "03:00",
  "30 3": "03:30",
  "00 4": "04:00",
  "30 4": "04:30",
  "00 5": "05:00",
  "30 5": "05:30",
  "00 6": "06:00",
  "30 6": "06:30",
  "00 7": "07:00",
  "30 7": "07:30",
  "00 8": "08:00",
  "30 8": "08:30",
  "00 9": "09:00",
  "30 9": "09:30",
  "00 10": "10:00",
  "30 10": "10:30",
  "00 11": "11:00",
  "30 11": "11:30",
  "00 12": "12:00",
  "30 12": "12:30",
  "00 13": "13:00",
  "30 13": "13:30",
  "00 14": "14:00",
  "30 14": "14:30",
  "00 15": "15:00",
  "30 15": "15:30",
  "00 16": "16:00",
  "30 16": "16:30",
  "00 17": "17:00",
  "30 17": "17:30",
  "00 18": "18:00",
  "30 18": "18:30",
  "00 19": "19:00",
  "30 19": "19:30",
  "00 20": "20:00",
  "30 20": "20:30",
  "00 21": "21:00",
  "30 21": "21:30",
  "00 22": "22:00",
  "30 22": "22:30",
  "00 23": "23:00",
  "30 23": "23:30",
};


class Replybot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      replybotInterval: props.user.replybotInterval / 60 || 4 * 60,
      replybotDefaultMessage: props.user.replybotDefaultMessage || "",
      replybotSchedules: props.user.replybotSchedules || [],
      editSchedule: {
        name: "",
        account: "",
        day: "**",
        startHour: "00 0",
        endHour: "00 0",
        message: "",
      },
    };
  }

    componentDidMount = async () => {
      const { user, updateUser } = this.props;

      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      const { intervalBetweenListings, schedules, replybotInterval, replybotSchedules, fbEmails, clEmails, replybot, nonUS } = await fetch(`${baseUrl}/getUser?jwt_token=${user.jwt_token}`, {
        method: "GET",
        headers,
      }).then(res => res.json());

      updateUser({ ...user, intervalBetweenListings, replybot, replybotInterval, replybotSchedules: replybotSchedules || [], schedules, fbEmails, clEmails, nonUS });
      this.setState({ replybotSchedules: replybotSchedules || [] });
    }

    onChange = ({ target: { name, value } }) =>
      this.setState({ [name]: value })


    onScheduleChange = ({ target: { name, value } }) => name === "day" && value === "**"
      ? this.setState({ editSchedule: { ...this.state.editSchedule, day: value, startHour: "00 0", endHour: "00 0" } })
      : this.setState({ editSchedule: { ...this.state.editSchedule, [name]: value } })


    saveSchedule = () => {
      const { editSchedule, replybotSchedules } = this.state;
      const { name, account, day, startHour, endHour, message } = editSchedule;
      const cronStart = `${startHour} * * ${day[0]}`;
      const cronEnd = `${endHour} * * ${day[0]}`;
      const schedule = { name, account, cronStart, cronEnd, message };

      if (editSchedule.index || editSchedule.index === 0) {
        replybotSchedules[editSchedule.index] = schedule;
      } else {
        replybotSchedules.push(schedule);
      }

      this.setState({ replybotSchedules, editSchedule: { name: "", account: "", day: "**", startHour: "00 0", endHour: "00 0", message: "" } });
    }

    onSubmit = e => {
      e.preventDefault();
      const { replybotInterval, replybotDefaultMessage, replybotSchedules } = this.state;
      const { user, updateUser } = this.props;
      const { intervalBetweenListings, schedules, fbEmails, clEmails } = user;

      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      fetch(`${baseUrl}/updateUser?jwt_token=${user.jwt_token}`, {
        method: "POST",
        headers,
        body: JSON.stringify({ intervalBetweenListings, schedules, fbEmails, clEmails, replybotInterval: replybotInterval * 60, replybotDefaultMessage, replybotSchedules }),
      })
        .then(response => {
          switch (response.status) {
            case 401:
              throw new Error("Subscription expired! Please renew subscription.");
            case 200:
              return response.json();
            default:
              throw new Error(`error connecting to server: ${response.status} ${response.statusText}`);
          }
        })
        .then(() => updateUser({ ...user, replybotInterval: replybotInterval * 60, replybotDefaultMessage, replybotSchedules }))
        .then(() => alert("AutoReply settings updated"))
        .catch(alert);
    }

    render() {
      const { fbEmails, replybotInterval } = this.props.user;
      const { replybotDefaultMessage, replybotSchedules, editSchedule } = this.state;

      return (
        <div className="container-fluid" >
          <form className="col-4 ms-2" onSubmit={this.onSubmit}>
            <div className="mb-3">
              <label htmlFor="replybotInterval" className="form-label mb-1">Interval Between New Message Checks (minutes)</label>
              <div className="input-group">
                <input type="number" min="4" name="replybotInterval" defaultValue={replybotInterval / 60} className="form-control" onChange={this.onChange} id="replybotInterval" />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="replybotDefaultMessage" className="form-label mb-1">Default Response Message:</label>
              <textarea name="replybotDefaultMessage" value={replybotDefaultMessage} className="form-control" onChange={this.onChange} id="replybotDefaultMessage" aria-describedby="replybotDefaultMessage" rows={replybotDefaultMessage.split("\n").length + 1} />
            </div>
            <div className="mb-1">
              <span>Autoreply schedules:</span>
            </div>

            <div className="mb-4">
              {replybotSchedules.length
                ? replybotSchedules.map((s, index) => {
                  const cronStartElements = s.cronStart.split(" ");
                  const day = cronStartElements[4];
                  const startHour = cronStartElements[0] + " " + cronStartElements[1];
                  const cronEndElements = s.cronEnd.split(" ");
                  const endHour = cronEndElements[0] + " " + cronEndElements[1];

                  return <div className="mb-1" key={s.name + s.cronEnd + s.cronEnd}>
                    <button type="button" className="btn btn-outline-dark" onClick={() => this.setState({ editSchedule: { name: s.name, account: s.account, day, startHour, endHour, message: s.message, index } })} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                      {s.name || `${s.account}, ${days[day]} @ ${hours[startHour]} - ${hours[endHour]}`}
                    </button>
                  </div>;
                })
                : <span>(none)</span>}
            </div>

            <div className="mb-2">
              <button type="button" className="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                <i className="fas fa-plus"></i> Add schedule
              </button>
            </div>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">{editSchedule.index || editSchedule.index === 0 ? "Edit schedule" : "Add schedule"} </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.setState({ editSchedule: { name: "", account: "", day: "**", startHour: "00 0", endHour: "00 0", message: "" } })}></button>
                  </div>
                  <div className="modal-body mx-5">
                    {
                      fbEmails && fbEmails.length
                        ? <>
                          <div className="mb-3">
                            <label htmlFor="name" className="form-label mb-1">Name:</label>
                            <input name="name" value={editSchedule.name} className="form-control" onChange={this.onScheduleChange} id="name" aria-describedby="name" />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="day" className="form-label mb-1">Facebook Account:</label>
                            <select value={editSchedule.account} className="form-select" name="account" onChange={this.onScheduleChange} >
                              <option disabled={true} value="">none</option>
                              {fbEmails.map(e => <option key={e} value={e}>{e}</option>)}
                            </select>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="day" className="form-label mb-1">Day:</label>
                            <select value={editSchedule.day} className="form-select" name="day" onChange={this.onScheduleChange} >
                              {Object.keys(days).map(d => <option key={d} value={d}>{days[d]}</option>)}
                            </select>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="startHour" className="form-label mb-1">Start Time:</label>
                            <select value={editSchedule.day === "**" ? "00 0" : editSchedule.startHour} className="form-select" name="startHour" onChange={this.onScheduleChange} disabled={editSchedule.day === "**"} >
                              {Object.keys(hours).map(h => <option key={h} value={h}>{hours[h]}</option>)}
                            </select>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="endHour" className="form-label mb-1">End Time:</label>
                            <select value={editSchedule.day === "**" ? "00 0" : editSchedule.endHour} className="form-select" name="endHour" onChange={this.onScheduleChange} disabled={editSchedule.day === "**"} >
                              {Object.keys(hours).map(h => <option key={h} value={h}>{hours[h]}</option>)}
                            </select>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="message" className="form-label mb-1">Message:</label>
                            <textarea name="message" value={editSchedule.message} className="form-control" onChange={this.onScheduleChange} id="message" aria-describedby="message" rows={editSchedule.message.split("\n").length + 1} />
                          </div>
                        </>
                        : <span>Please register a facebook account in main settings before proceeding.</span>
                    }
                  </div>
                  <div className="modal-footer">

                    {editSchedule.index || editSchedule.index === 0
                      ? <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => this.setState({ replybotSchedules: replybotSchedules.filter((s, i) => i !== editSchedule.index), editSchedule: { name: "", account: "", day: "**", startHour: "00 0", endHour: "00 0", message: "" } })}>Remove</button>
                      : <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>}
                    <button type="button" className="btn btn-dark" data-bs-dismiss="modal" onClick={this.saveSchedule} disabled={!editSchedule.account || !editSchedule.message || (editSchedule.startHour !== "00 0" && hours[editSchedule.startHour] >= hours[editSchedule.endHour])}>Ok</button>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-dark mt-4 px-4">Save</button>
          </form >

        </div >
      );
    }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  updateUser: (user) => dispatch(updateUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Replybot);
