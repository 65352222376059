import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUser } from "../actions";
import Checkbox from "../components/Checkbox";

const baseUrl = "https://us-central1-lazyposter.cloudfunctions.net";

const days = {
  "*": "daily",
  "1": "monday",
  "2": "tuesday",
  "3": "wednesday",
  "4": "thursday",
  "5": "friday",
  "6": "saturday",
  "7": "sunday",
};

const hours = {
  "00 0": "00:00",
  "30 0": "00:30",
  "00 1": "01:00",
  "30 1": "01:30",
  "00 2": "02:00",
  "30 2": "02:30",
  "00 3": "03:00",
  "30 3": "03:30",
  "00 4": "04:00",
  "30 4": "04:30",
  "00 5": "05:00",
  "30 5": "05:30",
  "00 6": "06:00",
  "30 6": "06:30",
  "00 7": "07:00",
  "30 7": "07:30",
  "00 8": "08:00",
  "30 8": "08:30",
  "00 9": "09:00",
  "30 9": "09:30",
  "00 10": "10:00",
  "30 10": "10:30",
  "00 11": "11:00",
  "30 11": "11:30",
  "00 12": "12:00",
  "30 12": "12:30",
  "00 13": "13:00",
  "30 13": "13:30",
  "00 14": "14:00",
  "30 14": "14:30",
  "00 15": "15:00",
  "30 15": "15:30",
  "00 16": "16:00",
  "30 16": "16:30",
  "00 17": "17:00",
  "30 17": "17:30",
  "00 18": "18:00",
  "30 18": "18:30",
  "00 19": "19:00",
  "30 19": "19:30",
  "00 20": "20:00",
  "30 20": "20:30",
  "00 21": "21:00",
  "30 21": "21:30",
  "00 22": "22:00",
  "30 22": "22:30",
  "00 23": "23:00",
  "30 23": "23:30",
};


class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intervalBetweenListings: props.user.intervalBetweenListings / 60,
      schedules: props.user.schedules || [],
      email: "",
      clEmail: "",
      fbEmails: props.user.fbEmails || [],
      clEmails: props.user.clEmails || [],
      editSchedule: {
        day: "*",
        hour: "00 0",
        campaign: [],
      },
      nonUS: props.user.nonUS || false,
    };
  }

    componentDidMount = async () => {
      const { user, updateUser } = this.props;

      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      const { intervalBetweenListings, schedules, fbEmails, clEmails, replybot, replybotInterval, replybotDefaultMessage, replybotSchedules, nonUS } = await fetch(`${baseUrl}/getUser?jwt_token=${user.jwt_token}`, {
        method: "GET",
        headers,
      }).then(res => res.json());

      updateUser({ ...user, intervalBetweenListings, replybot, replybotInterval, replybotDefaultMessage, replybotSchedules, schedules, fbEmails, clEmails, nonUS });
      this.setState({ schedules: schedules || [], fbEmails: fbEmails || [], clEmails: clEmails || [], nonUS });
    }

    onChange = ({ target: { name, value } }) =>
      this.setState({ [name]: value })

    onCampaignChange = (value, i) => {
      const { editSchedule } = this.state;
      const { campaign } = editSchedule;

      campaign[i] = value;
      editSchedule.campaign = campaign.filter(c => c.trim());

      this.setState({ editSchedule });
    }

    addFacebookEmail = () =>
      this.setState({ fbEmails: [...this.state.fbEmails, this.state.email], email: "" })

    addCraigslistEmail = () =>
      this.setState({ clEmails: [...this.state.clEmails, this.state.clEmail], clEmail: "" })


    saveSchedule = () => {
      const { editSchedule, schedules } = this.state;
      const cron = `${editSchedule.hour} * * ${editSchedule.day}`;
      const campaign = editSchedule.campaign.filter(c => c.trim());
      const schedule = { cron, campaign };

      if (editSchedule.index || editSchedule.index === 0) {
        schedules[editSchedule.index] = schedule;
      } else {
        schedules.push(schedule);
      }

      this.setState({ schedules, editSchedule: { day: "*", hour: "00 0", campaign: [] } });
    }

    onSubmit = e => {
      e.preventDefault();
      const { intervalBetweenListings, schedules, fbEmails, clEmails, nonUS } = this.state;
      const { user, updateUser } = this.props;

      if (intervalBetweenListings < 5) {
        alert("Interval between listings must be larger than 5 minutes");
        return;
      }

      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      fetch(`${baseUrl}/updateUser?jwt_token=${user.jwt_token}`, {
        // fetch(`http://localhost:8080?jwt_token=${user.jwt_token}`, {
        method: "POST",
        headers,
        body: JSON.stringify({ intervalBetweenListings: intervalBetweenListings * 60, schedules: schedules.map(s => s.campaign?.length ? s : s.cron || s), fbEmails, clEmails, nonUS }),
      })
        .then(response => {
          switch (response.status) {
            case 401:
              throw new Error("Subscription expired! Please renew subscription.");
            case 200:
              return response.json();
            default:
              throw new Error(`error connecting to server: ${response.status} ${response.statusText}`);
          }
        })
        .then(() => updateUser({ ...user, intervalBetweenListings: intervalBetweenListings * 60, schedules, fbEmails, clEmails, nonUS }))
        .then(() => alert("Settings updated"))
        .catch(alert);
    }

    render() {
      const { intervalBetweenListings, campaigns, scheduling, subscriptions, fbEmailLimit, clEmailLimit } = this.props.user;
      const { schedules, editSchedule, fbEmails, clEmails, nonUS } = this.state;
      const { campaign } = editSchedule;

      return (
        <div className="container-fluid" >
          <form className="col-4 ms-2" onSubmit={this.onSubmit}>
            <div className="mb-3">
              <label htmlFor="intervalBetweenListings" className="form-label mb-1">Interval Between Posts (minutes)</label>
              <div className="input-group">
                <input type="number" min="3" name="intervalBetweenListings" defaultValue={intervalBetweenListings / 60} className="form-control" onChange={this.onChange} id="intervalBetweenListings" />
              </div>
            </div>
            {
              scheduling &&
                <>
                  <div className="mb-1">
                    <span>Scheduled runs:</span>
                  </div>

                  <div className="mb-4">
                    {schedules.length
                      ? schedules.map((s, index) => {
                        const cron = s.cron || s;
                        const cronElements = cron.split(" ");
                        const day = cronElements[4];
                        const hour = cronElements[0] + " " + cronElements[1];

                        const campaign = s.campaign || [];
                        const campaignDisplay = campaign?.join(", ") || "(all)";

                        return <div className="mb-1" key={cron + campaignDisplay}>
                          <button type="button" className="btn btn-outline-dark" onClick={() => this.setState({ editSchedule: { day, hour, campaign: [...campaign], index } })} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            {`${days[day]} @ ${hours[hour]}, campaign: ${campaignDisplay}`}
                          </button>
                        </div>;
                      })
                      : <span>(none)</span>}
                  </div>

                  <div className="mb-2">
                    <button type="button" className="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                      <i className="fas fa-plus"></i> Add schedule
                    </button>
                  </div>

                  <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdropLabel">{editSchedule.index || editSchedule.index === 0 ? "Edit schedule" : "Add schedule"} </h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.setState({ editSchedule: { day: "*", hour: "00 0", campaign: [] } })}></button>
                        </div>
                        <div className="modal-body mx-5">
                          <div className="mb-3">
                            <label htmlFor="day" className="form-label mb-1">Day:</label>
                            <select value={editSchedule.day} className="form-select" name="day" onChange={e => this.setState({ editSchedule: { ...editSchedule, day: e.target.value } })} >
                              {Object.keys(days).map(d => <option key={d} value={d}>{days[d]}</option>)}
                            </select>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="hour" className="form-label mb-1">Time:</label>
                            <select value={editSchedule.hour} className="form-select" name="hour" onChange={e => this.setState({ editSchedule: { ...editSchedule, hour: e.target.value } })} >
                              {Object.keys(hours).map(h => <option key={h} value={h}>{hours[h]}</option>)}
                            </select>
                          </div>

                          {
                            campaigns && Object.keys(campaigns).length &&
                              <div className="mb-3">
                                <label htmlFor="campaign" className="form-label mb-1">Campaign(s)</label>
                                {
                                  (campaign.length < 5 ? [...campaign, ""] : campaign).map((c, i) =>
                                    (<div key={`campaign-${i}`}>
                                      <input list="campaigns" value={c || ""} className="form-control" onChange={e => this.onCampaignChange(e.target.value, i)} placeholder={!campaign.length ? "(all)" : null} name="campaign" />
                                      <datalist id="campaigns">
                                        {
                                          Object.keys(campaigns).map(c => (<option key={c} value={c} />))
                                        }
                                      </datalist>
                                    </div >)
                                  )
                                }
                              </div>
                          }
                        </div>
                        <div className="modal-footer">

                          {editSchedule.index || editSchedule.index === 0
                            ? <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => this.setState({ schedules: schedules.filter((s, i) => i !== editSchedule.index), editSchedule: { day: "*", hour: "00 0", campaign: [] } })}>Remove</button>
                            : <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>}
                          <button type="button" className="btn btn-dark" data-bs-dismiss="modal" onClick={this.saveSchedule}>Ok</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
            }
            {
              subscriptions.includes("facebook") &&
                <>
                  <div className="mb-1 mt-5">
                    <span>Registered facebook emails & phone numbers:</span>
                  </div>
                  {
                    fbEmails.map(e => <div key={e} className="input-group mb-3">
                      <input type="email" value={e} className="form-control" disabled={true} />
                    </div>)
                  }
                  {
                    fbEmails.length < fbEmailLimit &&
                      <div className="input-group mb-3">
                        <input type="email" name="email" value={this.state.email} className="form-control me-3" onChange={this.onChange} id="email" />
                        <button type="button" className="btn btn-dark" onClick={this.addFacebookEmail}>Add email</button>
                      </div>
                  }
                </>
            }
            {
              subscriptions.includes("craigslist") &&
                <>
                  <div className="mb-1 mt-5">
                    <span>Registered craigslist emails:</span>
                  </div>
                  {
                    clEmails.map(e => <div key={e + "-cl"} className="input-group mb-3">
                      <input type="email" value={e} className="form-control" disabled={true} />
                    </div>)
                  }
                  {
                    clEmails.length < clEmailLimit &&
                      <div className="input-group mb-3">
                        <input type="email" name="clEmail" value={this.state.clEmail} className="form-control me-3 mb-4" onChange={this.onChange} id="clEmail" />
                        <button type="button" className="btn btn-dark" onClick={this.addCraigslistEmail}>Add email</button>
                      </div>
                  }
                </>
            }


            <Checkbox display={subscriptions.includes("facebook")} name="nonUS" labels={["Non-US User"]} options={["nonUS"]} checked={nonUS ? ["nonUS"] : []} onChange={({ target }) => this.setState({ nonUS: target.checked })} />


            <button type="submit" className="btn btn-dark mt-4 px-4">Save</button>
          </form >

        </div >
      );
    }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  updateUser: (user) => dispatch(updateUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
