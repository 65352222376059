import React, { Component } from "react";

export default class CampaignsInput extends Component {
  render() {
    const { campaign, campaigns, onChange } = this.props;


    return (
      <div className="mb-5">
        <label htmlFor="campaign" className="form-label mb-1">Campaign</label>
        <input list="campaigns" defaultValue={campaign || ""} className="form-control" onChange={onChange} placeholder="none" name="campaign" required={false} />
        <datalist id="campaigns">
          {
            campaigns && Object.keys(campaigns).length ? Object.keys(campaigns).map(c => (<option key={c} value={c} />)) : null
          }
        </datalist>
        <hr />
      </div>
    );
  }
}
