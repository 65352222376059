import React, { Component } from "react";
import { connect } from "react-redux";
import base64 from "base-64";
import utf8 from "utf8";

const baseUrl = "https://us-central1-lazyposter.cloudfunctions.net";

class ImportExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "item",
      csv: "",
    };
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => resolve(base64.encode(utf8.encode(reader.result)));
      reader.onerror = error => reject(error);
    });
  };

    onFileSelect = async ({ target }) => {
      let csv = "";
      for (const file of target.files) {
        csv = await this.toBase64(file);
      }
      this.setState({ csv });
    }

    uploadCSV = e => {
      e.target.disabled = true;
      const { csv } = this.state;
      const { jwt_token } = this.props.user;
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "text/plain");
      fetch(`${baseUrl}/importCSV?jwt_token=${jwt_token}`, {
        // fetch(`http://localhost:8080?jwt_token=${jwt_token}`, {
        method: "POST",
        headers,
        body: csv,
      })
        .then(async response => {
          e.target.disabled = false;
          switch (response.status) {
            case 401:
              throw new Error("Subscription expired! Please renew subscription.");
            case 400:
              throw new Error(await response.text());
            case 200:
              return response.json();
            default:
              throw new Error(`error connecting to server: ${response.status} ${response.statusText}`);
          }
        })
        .then(() => {
          alert("CSV successfully uploaded!");
          this.props.history.push("/view-listings");
        })
        .catch(alert);
    }

    render = () => {
      const { jwt_token, subscription_active, types } = this.props.user;
      const { type } = this.state;

      return (
        <div className="ps-3">
          <div className="col-3 mt-3 mb-4">
            <h4 className="text-dark py-3">Import listings as CSV file:</h4>
            <input className="form-control" type="file" accept=".csv" id="csv" onChange={this.onFileSelect} disabled={!subscription_active} />
          </div>
          <button className="btn btn-dark btn-lg mb-3" onClick={this.uploadCSV} disabled={!subscription_active || !this.state.csv}>
            <i className="fas fa-upload"></i> Upload CSV
          </button>

          <hr />

          <div className="row col-4">
            <h4 className="text-dark py-3">Export listings as CSV file:</h4>
            <div className="mb-3">
              <label htmlFor="type" className="form-label mb-1">Listing type:</label>
              <select value={type} className="form-select" name="type" onChange={e => this.setState({ type: e.target.value })}>
                {
                  [...types, "template"].map(t => <option key={t} value={t}>{t}</option>)
                }
              </select>
            </div>
          </div>
          <a href={`${baseUrl}/exportCSV?type=${type}&jwt_token=${jwt_token}`} download className="btn btn-dark btn-lg me-4 mb-4">
            <i className="fas fa-download"></i> Download CSV
          </a>

          <br />

          <a href="https://storage.googleapis.com/lazyposter-app/guides/guide_csv_import.pdf" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg mb-4">
            <i className="far fa-file-pdf"></i> Import/Export Guide
          </a>
        </div>
      );
    }
}


const mapStateToProps = state => ({
  user: state.user,
});


export default connect(
  mapStateToProps,
  null
)(ImportExport);
