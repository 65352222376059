import { Component } from "react";
import { connect } from "react-redux";
import { updateUser } from "../actions";

const url = "https://us-central1-lazyposter.cloudfunctions.net/login";
// const url = "http://localhost:8080"

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      redirect: false,
    };
  }

    onChange = e => {
      const { name, value } = e.target;
      const user = { ...this.state.user, [name]: value };

      this.setState({ user });
    }

    onSubmit = e => {
      e.preventDefault();
      const { updateUser } = this.props;
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify({ ...this.state.user, email: this.state.user.email.toLowerCase() }),
      })
        .then(response => {
          switch (response.status) {
            case 400:
              throw new Error("Invalid credentials!");
            case 200:
              return response.json();
            default:
              throw new Error(`error connecting to server: ${response.status} ${response.statusText}`);
          }
        })
        .then(updateUser)
        .then(() => this.props.history.push("/dashboard"))
        .catch(alert);
    }

    render = () => {
      return (
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div className="card border-0 shadow rounded-3 my-5">
              <div className="card-body p-4 p-sm-5">
                <h5 className="card-title text-center mb-5 fw-light fs-5">Sign In</h5>
                <form onSubmit={this.onSubmit}>
                  <div className="form-floating mb-3">
                    <input type="email" name="email" className="form-control" id="floatingInput" onChange={this.onChange} placeholder="name@example.com" />
                    <label htmlFor="floatingInput">Email address</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input type="password" name="password" className="form-control" id="floatingPassword" onChange={this.onChange} placeholder="Password" />
                    <label htmlFor="floatingPassword">Password</label>
                  </div>

                  <div className="form-check mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="rememberPasswordCheck" />
                    <label className="form-check-label" htmlFor="rememberPasswordCheck">
                      Remember me
                    </label>
                  </div>
                  <div className="d-grid">
                    <button className="btn btn-primary btn-login text-uppercase fw-bold" type="submit">Sign
                      in</button>
                  </div>
                  <hr className="my-4" />
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
}


const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  updateUser: (user) => dispatch(updateUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
